///// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

////// helpers
import { roundingNum, totalSumPrice } from "../../../helpers/amounts";

///// fns
import {
  addProdInInvoice,
  delProdInInvoice,
  getProducts,
  getProductsInQr,
} from "../../../store/reducers/saleSlice";

////// components
import { Table, TableBody, TableCell, Tooltip } from "@mui/material";
import { TableContainer, TableHead } from "@mui/material";
import { TableRow, Paper } from "@mui/material";
import MyModals from "../../../common/MyModals/MyModals";

///// icons
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import DeleteIcon from "../../../assets/MyIcons/DeleteIcon";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

////// styles
import "./style.scss";
import GeneratePdfCheque from "../GeneratePdfCheque/GeneratePdfCheque";

const SaleProds = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const refInput = useRef(null);
  const refInputSum = useRef(null);

  const { invoice_guid } = location.state;

  const { data } = useSelector((state) => state.saveDataSlice);
  const { listProds } = useSelector((state) => state.saleSlice);

  const [sum, setSum] = useState("");
  const [modal, setModal] = useState({});
  const [price, setPrice] = useState("");

  const sendProd = async (e) => {
    e.preventDefault();
    const send = { qrcode: sum, seller_guid: data?.seller_guid };
    const res = await dispatch(getProductsInQr(send)).unwrap();
    if (!!res?.guid) {
      setModal(res);
      setTimeout(() => {
        refInputSum.current.focus();
      }, 200);
    } else {
      alert("Не удалось найти такой продукт");
    }
  };

  const getData = () => {
    const send = { invoice_guid, seller_guid: data?.seller_guid };
    dispatch(getProducts(send));
  };

  useEffect(() => {
    getData();
    setTimeout(() => {
      refInput.current.focus();
    }, 300);
  }, [invoice_guid]);

  const onChange = async (e, type) => {
    /// 1 - сразу отправляю запрос, 2 - после нажатия
    setSum(e.target.value);
    if (e.target.value?.length == 6 && type == 1) {
      const send = { qrcode: e.target.value, seller_guid: data?.seller_guid };
      const res = await dispatch(getProductsInQr(send)).unwrap();
      if (!!res?.guid) {
        setModal(res);
        setTimeout(() => {
          refInputSum.current.focus();
        }, 200);
      } else {
        alert("Не удалось найти такой продукт");
      }
    }
  };

  const onChangeCount = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setPrice(value);
    }
  };

  const clickDelProd = async (obj) => {
    const send = { product_guid: obj?.guid };
    const res = await dispatch(delProdInInvoice(send)).unwrap();
    if (res?.result == 0) {
      getData();
    }
  };

  const addProd = async (e) => {
    e.preventDefault();

    if (price == 0 || price == "") {
      alert("Введите сумму товара");
      return;
    }

    const sendData = {
      guid: modal?.guid,
      count: price,
      price: 0,
      invoice_guid,
      sale_price: modal?.sale_price,
    };
    const resp = await dispatch(addProdInInvoice(sendData)).unwrap();
    if (!!resp?.result) {
      getData();
      closeModal();
    }
  };

  const closeModal = () => {
    setModal({});
    setSum("");
    setPrice("");
    refInput.current.focus();
  };

  console.log(listProds, "listProds");

  const sendAcceptInvoice = () => {};

  return (
    <div className="saleProdsQR">
      <div className="saleProdsQR__inner">
        <div className="header">
          <form className="actionAddProd" onSubmit={sendProd}>
            <input
              ref={refInput}
              type="search"
              onChange={(e) => onChange(e, 1)}
              value={sum}
              maxLength={6}
            />
            <button className="saveAction" type="submit">
              <NoteAddIcon sx={{ width: 16, height: 16 }} />
              <p>Добавить товар</p>
            </button>
          </form>
          {/* <button className="saveAction">
            <LibraryAddIcon sx={{ width: 16, height: 16 }} />
            <p>Распечатать чек</p>
          </button> */}
          <GeneratePdfCheque list={listProds} />
        </div>
        <div className="saleProdsQR__table">
          <TableContainer
            component={Paper}
            sx={{ maxHeight: "100%" }}
            className="scroll_table standartTable"
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: "5%" }}>
                    №
                  </TableCell>
                  <TableCell style={{ width: "41%" }}>Продукт</TableCell>
                  <TableCell align="left" style={{ width: "15%" }}>
                    Цена за кг
                  </TableCell>
                  <TableCell align="left" style={{ width: "15%" }}>
                    Итоговый вес
                  </TableCell>
                  <TableCell align="left" style={{ width: "15%" }}>
                    Cумма продажи
                  </TableCell>
                  <TableCell align="center" style={{ width: "9%" }}>
                    *
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listProds?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      component="th"
                      scope="row"
                      style={{ width: "5%" }}
                    >
                      {listProds?.length - index}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ width: "41%" }}
                    >
                      {row?.product_name}
                    </TableCell>
                    <TableCell align="left" style={{ width: "15%" }}>
                      {roundingNum(row?.product_price)} сом
                    </TableCell>
                    <TableCell align="left" style={{ width: "15%" }}>
                      {roundingNum(row?.count)} кг
                    </TableCell>
                    <TableCell align="left" style={{ width: "15%" }}>
                      {/* <div className="countsBlock">
                        <input
                          type="text"
                          onChange={(e) => onChangeCount(e, row)}
                          value={roundingNum(row?.price)}
                          maxLength={10}
                          className="counts"
                        />
                      </div> */}
                      {roundingNum(row?.price)} сом
                    </TableCell>
                    <TableCell align="left" style={{ width: "9%" }}>
                      <div className="delIcon">
                        <button onClick={(e) => clickDelProd(row)}>
                          <DeleteIcon width={19} height={19} color={"red"} />
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} align="left" className="footerTable">
                    Итого к оплате
                  </TableCell>
                  <TableCell colSpan={2} align="left">
                    {+totalSumPrice(listProds)} сом
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    align="left"
                    style={{ fontWeight: "bold" }}
                  ></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <MyModals
        openModal={!!modal?.guid}
        closeModal={closeModal}
        title={modal?.product_name}
      >
        <form onSubmit={addProd} className="actionsAddProd">
          <div className="inputSend">
            <p>Сумма товара (сом)</p>
            <input
              ref={refInputSum}
              type="text"
              onChange={onChangeCount}
              value={price}
            />
          </div>
          <button className="saveAction" type="submit">
            <NoteAddIcon sx={{ width: 16, height: 16 }} />
            <p>Добавить товар</p>
          </button>
        </form>
      </MyModals>
    </div>
  );
};

export default SaleProds;
