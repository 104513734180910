import React from "react";
import { Route, Routes } from "react-router-dom";
import SaleProds from "../../components/SaleQrCodePage/SaleProds/SaleProds";
import SaleLayout from "../../LayoutsList/SaleLayout/SaleLayout";

const SaleQrCodePage = () => {
  return (
    <Routes>
      <Route element={<SaleLayout />}>
        {/* <Route path="/" element={<SaleQrCodePage />} /> */}
        <Route path="/main" element={<SaleProds />} />
        {/* <Route path="/search" element={<SaleProds />} />
        <Route path="/return_prod" element={<SaleProds />} /> */}
      </Route>
    </Routes>
  );
};

export default SaleQrCodePage;

// @media screen and (max-width: 370px) {
//   width: 340px;
// }
